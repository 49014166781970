<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item class="apListItem" v-on="on">
        <v-list-item-icon>
          <v-icon
            class="mr-2"
            small
            style="margin-top: -6px !important"
            dark
            icon
            size="18"
            v-bind="attrs"
            v-on="on"
          >
            mdi-cog-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <span> {{ $t("configurate", { name: $tc("") }) }}</span>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("configurate", { name: $t("sub_type") }) }}
      </v-card-title>
      <v-card-subtitle
        v-if="subservice.description"
        class="mt-0 text-center primary--text"
      >
        {{ subservice.reference }} -
        {{ subservice.description }}
      </v-card-subtitle>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row align="center">
          <v-col cols="7" class="">{{ $tc("discount", 2) }}</v-col>
          <v-col cols="5" class="">
            <DiscountsSubServiceList :subservice="subservice" />
          </v-col>
          <template v-if="$store.getters['auth/isStudio']">
            <v-col cols="7" class="">Artistas</v-col>
            <v-col cols="5" class="">
              <ArtistsSubServiceConfiguration
                :subservice="subservice"
                @update="$emit('update')"
              />
            </v-col>
          </template>
          <v-col cols="7" class="">{{ $t("consent-form.title_xs") }}</v-col>
          <v-col cols="5" class="">
            <ConsentFormSubserviceConfiguration
              :sub_service="subservice"
              @update="$emit('update')"
            />
          </v-col>
          <v-col cols="7" class="">Inventario</v-col>
          <v-col cols="5" class="">
            <InventorySubserviceConfiguration
              :sub_service="subservice"
              @update="$emit('update')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfigurationSubService",
  props: ["subservice"],
  data() {
    return { dialog: false };
  },
  components: {
    DiscountsSubServiceList: () =>
      import(
        "@/components/user/settings/configuracion/subservices/configuration/DiscountsSubServiceList"
      ),
    ArtistsSubServiceConfiguration: () =>
      import(
        "@/components/user/settings/configuracion/subservices/configuration/ArtistsSubServiceConfiguration"
      ),
    ConsentFormSubserviceConfiguration: () =>
      import(
        "@/components/user/settings/configuracion/subservices/configuration/ConsentFormSubserviceConfiguration"
      ),
    InventorySubserviceConfiguration: () =>
      import(
        "@/components/user/settings/configuracion/subservices/configuration/InventorySubserviceConfiguration"
      ),
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>
